// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.about-us{
    margin: 0 auto;  
    width: 50%;
    padding: 50px;  
    text-align: center; 
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.2);
}
  .container {
    max-width: 90%; /* Changed to 90% of the viewport width */
    margin: 0 auto; /* Center the container */
    padding: 20px;
    text-align: center; /* Center align text */
    
  }
  
  .input-field {
    width: calc(100% - 40px); /* Adjusted width */
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .message.user {
    margin: 8px 0;
    background-color: #e7cf80;
    align-self: flex-end; /* Aligns user messages to the right */
  }
  
  .submit-button {
    background-color: #e7cf80;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .summary-container,
  .sources-container {
    margin-top: 20px;
  }
  
  
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/AboutUs.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,2BAA2B;IAC3B,oCAAoC;AACxC;EACE;IACE,cAAc,EAAE,yCAAyC;IACzD,cAAc,EAAE,yBAAyB;IACzC,aAAa;IACb,kBAAkB,EAAE,sBAAsB;;EAE5C;;EAEA;IACE,wBAAwB,EAAE,mBAAmB;IAC7C,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE,aAAa;IACb,yBAAyB;IACzB,oBAAoB,EAAE,sCAAsC;EAC9D;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;;IAEE,gBAAgB;EAClB;;;;EAIA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["\n.about-us{\n    margin: 0 auto;  \n    width: 50%;\n    padding: 50px;  \n    text-align: center; \n    backdrop-filter: blur(20px);\n    background: rgba(255, 255, 255, 0.2);\n}\n  .container {\n    max-width: 90%; /* Changed to 90% of the viewport width */\n    margin: 0 auto; /* Center the container */\n    padding: 20px;\n    text-align: center; /* Center align text */\n    \n  }\n  \n  .input-field {\n    width: calc(100% - 40px); /* Adjusted width */\n    padding: 10px;\n    margin-bottom: 10px;\n    font-size: 16px;\n  }\n  .message.user {\n    margin: 8px 0;\n    background-color: #e7cf80;\n    align-self: flex-end; /* Aligns user messages to the right */\n  }\n  \n  .submit-button {\n    background-color: #e7cf80;\n    color: #fff;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .summary-container,\n  .sources-container {\n    margin-top: 20px;\n  }\n  \n  \n  \n  ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  li {\n    margin-bottom: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
