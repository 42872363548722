// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.1);
  }
  
  .contact-us h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-us form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .contact-us label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-us input,
  .contact-us textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .contact-us textarea {
    min-height: 100px;
  }
  
  .contact-us button {
    background-color: #e7cf80;;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .contact-us button:hover {
    background-color: #ffe99f;;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,mCAA2B;YAA3B,2BAA2B;IAC3B,oCAAoC;EACtC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;;IAEE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".contact-us {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    text-align: center;\n    backdrop-filter: blur(20px);\n    background: rgba(255, 255, 255, 0.1);\n  }\n  \n  .contact-us h1 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .contact-us form {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .contact-us label {\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .contact-us input,\n  .contact-us textarea {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100%;\n  }\n  \n  .contact-us textarea {\n    min-height: 100px;\n  }\n  \n  .contact-us button {\n    background-color: #e7cf80;;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 10px;\n  }\n  \n  .contact-us button:hover {\n    background-color: #ffe99f;;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
