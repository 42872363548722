// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: fixed;
    top: 0;
    left: 0; /* corrected from 'left: 200' to cover the entire screen */
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's on top of everything */
  }


.modal-content{
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px; /* Set a fixed width for the modal */
  max-width: 90%; /* Allow it to shrink on small screens */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adds a shadow for a more defined look */
  position: relative; /* For positioning close button relative to the modal */
  text-align: center; /* Center-align content */
}
.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.modal-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.closeButton:hover {
  color: #ff0000; /* Optional hover effect */
}`, "",{"version":3,"sources":["webpack://./src/style/PayPal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO,EAAE,0DAA0D;IACnE,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,wCAAwC;EACzD;;;AAGF;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,YAAY,EAAE,oCAAoC;EAClD,cAAc,EAAE,wCAAwC;EACxD,yCAAyC,EAAE,0CAA0C;EACrF,kBAAkB,EAAE,uDAAuD;EAC3E,kBAAkB,EAAE,yBAAyB;AAC/C;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,cAAc,EAAE,0BAA0B;AAC5C","sourcesContent":[".modal {\n    position: fixed;\n    top: 0;\n    left: 0; /* corrected from 'left: 200' to cover the entire screen */\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999; /* Make sure it's on top of everything */\n  }\n\n\n.modal-content{\n  background-color: white;\n  padding: 20px;\n  border-radius: 8px;\n  width: 400px; /* Set a fixed width for the modal */\n  max-width: 90%; /* Allow it to shrink on small screens */\n  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adds a shadow for a more defined look */\n  position: relative; /* For positioning close button relative to the modal */\n  text-align: center; /* Center-align content */\n}\n.modal-title {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n.modal-description {\n  font-size: 16px;\n  margin-bottom: 20px;\n  color: #666;\n}\n\n.closeButton {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background-color: transparent;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n}\n\n.closeButton:hover {\n  color: #ff0000; /* Optional hover effect */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
